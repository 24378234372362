var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getAvaBackendEndpoint, httpRequestWithToken } from '../../utils/http';
const patchUser = ({ avaId, firebaseAuthUID, channel, data, }) => __awaiter(void 0, void 0, void 0, function* () {
    return httpRequestWithToken({
        url: getUsersUrl(avaId, 'v1.1', firebaseAuthUID, channel),
        method: 'PATCH',
        payload: data,
    });
});
const updateMetrics = ({ avaId, firebaseAuthUID, convoMetrics, }) => __awaiter(void 0, void 0, void 0, function* () { return patchUser({ avaId, firebaseAuthUID, data: { convoMetrics } }); });
// Updates the user's feature flags.
//
// The database refers to this value as 'flags', while the application
// state refers to this as features.
// TODO: Should update the application state to refer to user's features
// as either featureFlags or just flags.
const updateFeatures = ({ avaId, firebaseAuthUID, features, }) => __awaiter(void 0, void 0, void 0, function* () { return patchUser({ avaId, firebaseAuthUID, data: { flags: features } }); });
const updateUserName = ({ avaId, firebaseAuthUID, userName, }) => __awaiter(void 0, void 0, void 0, function* () { return patchUser({ avaId, firebaseAuthUID, data: { userName } }); });
const updateHearingProfile = ({ avaId, firebaseAuthUID, hearingProfile, }) => __awaiter(void 0, void 0, void 0, function* () {
    // NOTE: There are 3 main hearing profiles (deaf, hard-of-hearing, hearing)
    // and 2 other sub profiles. Since it was not part of the design to implement
    // all 5 profiles, this is hard-coded for now. If we do implement all 5
    // profiles, then we can use HEARING_PROFILE in utils/user.js
    if (!(hearingProfile >= 0 && hearingProfile <= 4)) {
        throw new Error('Invalid hearing profile');
    }
    return patchUser({ avaId, firebaseAuthUID, data: { hearingProfile } });
});
const updateJoinMetrics = ({ avaId, firebaseAuthUID, joinMetrics, }) => __awaiter(void 0, void 0, void 0, function* () {
    patchUser({ avaId, firebaseAuthUID, data: { joinMetrics } });
});
const updateInviteMetrics = ({ avaId, firebaseAuthUID, inviteMetrics, }) => __awaiter(void 0, void 0, void 0, function* () { return patchUser({ avaId, firebaseAuthUID, data: { inviteMetrics } }); });
const updateSettings = ({ avaId, firebaseAuthUID, settings, }) => __awaiter(void 0, void 0, void 0, function* () { return patchUser({ avaId, firebaseAuthUID, data: { settings } }); });
const updateRole = ({ avaId, firebaseAuthUID, role }) => __awaiter(void 0, void 0, void 0, function* () { return patchUser({ avaId, firebaseAuthUID, data: { role } }); });
const getOrCreateProfile = ({ firebaseAuthUID, channel }) => __awaiter(void 0, void 0, void 0, function* () {
    // to future engineers: we used to prefetch the token in index.html but it was a security vulnerability, please don't
    return patchUser({ avaId: 'me', firebaseAuthUID, channel, data: {} });
});
const getProfile = ({ user }) => __awaiter(void 0, void 0, void 0, function* () {
    const firebaseAuthUID = user ? user.firebaseAuthUID || user.id : null;
    const url = getUsersUrl('me', 'v1', firebaseAuthUID);
    return httpRequestWithToken({
        url,
        method: 'GET',
    });
});
const getGuestProfile = ({ avaId, firebaseAuthUID, }) => __awaiter(void 0, void 0, void 0, function* () {
    let url = `${getAvaBackendEndpoint()}/api/v1/guest-users/${avaId}`;
    if (firebaseAuthUID) {
        url = `${url}?firebaseAuthUID=${firebaseAuthUID}`;
    }
    return httpRequestWithToken({
        url,
        method: 'GET',
    });
});
const createGuestProfile = ({ roomId, firebaseAuthUID, }) => __awaiter(void 0, void 0, void 0, function* () {
    let url = `${getAvaBackendEndpoint()}/api/v1/guest-users/me`;
    if (firebaseAuthUID) {
        url = `${url}?firebaseAuthUID=${firebaseAuthUID}`;
    }
    return httpRequestWithToken({
        url,
        method: 'POST',
        payload: { roomId },
    });
});
export const getTurnCredentials = () => __awaiter(void 0, void 0, void 0, function* () {
    const url = `${getAvaBackendEndpoint()}/api/v1/tokens/turn-credentials`;
    try {
        const result = yield httpRequestWithToken({
            url,
            method: 'GET',
        });
        if (result && result.data && result.data.ok) {
            return result.data.turnCredentials || [];
        }
        return [];
    }
    catch (e) {
        console.log(e);
        return [];
    }
});
export const beginChatWithUser = (avaId, msg, firebaseAuthUID) => __awaiter(void 0, void 0, void 0, function* () {
    // firebaseAuthUID is the firebase UID of the SCRIBE, not the user being chatted with
    const url = `${getAvaBackendEndpoint()}/api/v1.1/users/beginChat?firebaseAuthUID=${firebaseAuthUID}`;
    return httpRequestWithToken({
        url,
        method: 'POST',
        payload: { avaId, messageFromUser: '👋', messageToUser: msg },
    });
});
const getUsersUrl = (avaId, version, firebaseAuthUID, channel) => {
    const url = `${getAvaBackendEndpoint()}/api/${version}/users/${avaId}`;
    if (firebaseAuthUID) {
        if (channel) {
            return `${url}?firebaseAuthUID=${firebaseAuthUID}&channel=${channel}`;
        }
        return `${url}?firebaseAuthUID=${firebaseAuthUID}`;
    }
    return url;
};
const terminateCall = ({ avaId, sid, uid }) => __awaiter(void 0, void 0, void 0, function* () {
    const url = `${getAvaBackendEndpoint()}/api/v1/calls/${sid}?avaId=${avaId}&firebaseAuthUID=${uid}`;
    return httpRequestWithToken({ url, method: 'DELETE' });
});
const createTwilioCall = ({ avaId, calleeInternationalNumber, conferenceType, roomId, sendDigits, speechLang, firebaseAuthUID, }) => __awaiter(void 0, void 0, void 0, function* () {
    const url = `${getAvaBackendEndpoint()}/api/v1/calls?avaId=${avaId}&firebaseAuthUID=${firebaseAuthUID}`;
    return httpRequestWithToken({
        url,
        method: 'POST',
        payload: {
            roomId,
            calleeInternationalNumber,
            sendDigits,
            speechLang,
            conferenceType,
        },
    });
});
const findOrganizations = ({ firebaseAuthUID, orgName, orgType, }) => __awaiter(void 0, void 0, void 0, function* () {
    const url = `${getAvaBackendEndpoint()}/api/v1/find-organizations?keywords=${orgName}&firebaseAuthUID=${firebaseAuthUID}&orgType=${orgType}`;
    return httpRequestWithToken({
        url,
        method: 'GET',
    });
});
const getUserCredits = ({ avaId, firebaseAuthUID }) => __awaiter(void 0, void 0, void 0, function* () {
    const url = `${getAvaBackendEndpoint()}/api/v1.1/user-credits/${avaId}?avaId=${avaId}&firebaseAuthUID=${firebaseAuthUID}`;
    return httpRequestWithToken({
        url,
        method: 'GET',
    });
});
const getRoomId = ({ token }) => __awaiter(void 0, void 0, void 0, function* () { return httpRequestWithToken({ url: `${getAvaBackendEndpoint()}/api/v1/tokens/${token}`, method: 'GET' }); });
const saveReport = ({ report, firebaseAuthUID }) => __awaiter(void 0, void 0, void 0, function* () {
    return httpRequestWithToken({
        url: `${getAvaBackendEndpoint()}/api/v1/reports?firebaseAuthUID=${firebaseAuthUID}`,
        method: 'POST',
        payload: report,
    });
});
const getTtsVoices = ({ firebaseAuthUID, lang, gender, voiceId, }) => __awaiter(void 0, void 0, void 0, function* () {
    const url = `${getAvaBackendEndpoint()}/api/v1/users/tts-voices-sample?firebaseAuthUID=${firebaseAuthUID}`;
    return httpRequestWithToken({
        url,
        method: 'POST',
        payload: { lang, gender, voiceId },
    });
});
const getTtsGenderPreference = ({ firebaseAuthUID }) => __awaiter(void 0, void 0, void 0, function* () {
    const url = `${getAvaBackendEndpoint()}/api/v1/users/tts-gender-preference?firebaseAuthUID=${firebaseAuthUID}`;
    return httpRequestWithToken({
        url,
        method: 'GET',
    });
});
const postTtsGenderPreference = ({ firebaseAuthUID, gender }) => __awaiter(void 0, void 0, void 0, function* () {
    const url = `${getAvaBackendEndpoint()}/api/v1/users/tts-gender-preference?firebaseAuthUID=${firebaseAuthUID}`;
    return httpRequestWithToken({
        url,
        method: 'POST',
        payload: { gender },
    });
});
const postTtsVoicePreference = ({ firebaseAuthUID, voiceId }) => __awaiter(void 0, void 0, void 0, function* () {
    const url = `${getAvaBackendEndpoint()}/api/v1/users/tts-voice?firebaseAuthUID=${firebaseAuthUID}`;
    return httpRequestWithToken({
        url,
        method: 'POST',
        payload: { voiceId },
    });
});
export const users = {
    getOrCreateProfile,
    getProfile,
    updateFeatures,
    updateHearingProfile,
    updateInviteMetrics,
    updateJoinMetrics,
    updateMetrics,
    updateRole,
    updateSettings,
    updateUserName,
};
export const tts = {
    getTtsVoices,
    getTtsGenderPreference,
    postTtsVoicePreference,
    postTtsGenderPreference,
};
export const guestUsers = {
    getGuestProfile,
    createGuestProfile,
};
export const userCredits = {
    getUserCredits,
};
export const conversations = {
    getRoomId,
};
export const calls = {
    createTwilioCall,
    terminateCall,
};
export const organizations = {
    findOrganizations,
};
export const reports = {
    saveReport,
};
